import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

const ErrorModal = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  function renderErrors() {
    var {errors} = props;

    if (errors.length === 0)
      return null;

      return errors.map((error, index) => <p key={index}>{error}</p>)
  }

  return (
    <div>
      <Modal isOpen={open}>
        <ModalHeader className="alert alert-danger">Atenção</ModalHeader>
        <ModalBody>
          
          {/* {renderErrors()} */}

        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.closeModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch({ type: "HIDE_ERROR" }),
  };
};
const mapStateToProps = (state) => {
  return {
    open: state.errors.open,
    errors: state.errors.errors,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
