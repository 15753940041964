
import "raf/polyfill";
import "font-awesome/css/font-awesome.min.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Interceptor from "./helpers/Interceptor";
import ErrorModal from "./components/organisms/modal-error";

import App from "./App";

import configureStore from "./store/store";
const store = configureStore();

Interceptor.interceptor(store);

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ErrorModal />
  </Provider>,
  document.getElementById("app")
);
