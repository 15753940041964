const paths = {
  aviso: "/aviso",

  bancoImagem: "/bancoimagem",
  bancoImagemCategoria: "/bancoimagemcategoria",

  bannerLogin: "/bannerlogin",

  usuario: "/usuario",
  usuarioTipo: "/usuariotipo",
  usuarioMeusDados: "/meusdados",

  regional: "/regional",
  regionalVerba: "/regional-verba",
  canal: "/canal",
  loja: "/loja",
  materialcomunicacao: "/materialcomunicacao",
  materialcomunicacaoTipo: "/materialcomunicacaotipo",
  fornecedor: "/fornecedor",

  unidade: "/unidade",

  chamadotipo: "/chamadotipo",

  pedidotipo: "/pedidotipo",

  perguntafrequente: "/perguntafrequente",

  produto: "/produto",
  produtoCategoria: "/produtocategoria",

  produtoatividade: "/produtoatividade",
  
  fluxo: "/fluxo",

  formaPagamento: "/formapagamento",

  motivoAcao: "/motivoacao",

  acaoPromocionalCategoria: "/acaoPromocionalCategoria",
  acaoPromocionalCampoTipo: "/acaoPromocionalCampoTipo",    
  acaoPromocionalCampo: "/acaoPromocionalCampo",  
  acaoPromocional: "/acaoPromocional",

  extratos: "/extrato",
  extratoUsuarios: "/extrato/usuarios",
  extratoLojas: "/extrato/lojas",
  extratoProdutos: "/extrato/produtos",

  segmento: "/segmento",

  valorDiagramacao: "/valordiagramacao",
  valorFrete: "/valorfrete",
  valorProducao: "/valorproducao",

  layoutArte: "/layoutarte",
  layoutArteAcao: "/layoutarteacao",
  campanha: "/campanha",
  rede: "/rede",

  solicitacao: "/solicitacao",
  solicitacaoFluxo: "/solicitacaofluxo",

  etapalabel: "/etapalabel",
  
  solicitacaoAlterar: "/solicitacao/alterar",
  solicitacaoAlterarDados: "/solicitacao/alterar-dados",
  solicitacaoAlterarEntrega: "/solicitacao/alterar-entrega",
  solicitacaoAlterarProdutos: "/solicitacao/alterar-produtos",

  solicitacaoValores: "/solicitacao/valores",
  solicitacaoPendencias: "/alterar-pendencia",
}

export default paths;