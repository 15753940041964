import React from 'react';

import Header from '../../organisms/header/index'
import Sidebar from '../../organisms/sidebar/index'
import Offsidebar from '../../organisms/offsidebar/index'
import Footer from '../../organisms/footer/index'

const Base = props => (
    <div className="wrapper">
        <Header />

        <Sidebar />

        <section className="section-container">
            { props.children }
        </section>

        <Footer />
    </div>
)

export default Base;
