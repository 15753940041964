export const SHOWERROR = "SHOW_ERROR";
export const HIDEERROR = "HIDE_ERROR";

/**
 * Change current theme path
 */
export function show(errors) {
  return { type: SHOWERROR, payload: errors };
}

export function hide() {
  return { type: HIDEERROR, payload: [] };
}
