import { SHOWERROR } from "../actions/actions";
import { HIDEERROR } from "../actions/actions";

const initialState = {
  open: false,
  errors: [],
};

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOWERROR:
      return {
        ...state,
        open: true,
        errors: action.payload,
      };
    case HIDEERROR:
      return {
        ...state,
        open: false,
        errors: [],
      };
    default:
      return state;
  }
};

export default errorsReducer;
