import axios from "axios";
import instance from "../../helpers/Axios.Config";
import { endpoint, prodEndpoint } from "../../config";

var url = process.env.NODE_ENV === "development" ? endpoint : prodEndpoint;

async function login(username, password) {
  try {
    var request = {
      username,
      password,
    };

    var result = await instance.post(url + "Login/", request); // aqui pode usar o axios porque esse metodo na api é AllowAnonymous
    //console.log(result);
    
    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function logout() {
  try {
    var result = await instance.get(url + "logout");

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export { login, logout };
