import axios from "axios";
import { endpoint, prodEndpoint } from "../config";
import { getToken } from "../services/auth";
import { clearToken } from "../services/auth";

var url = process.env.NODE_ENV === "development" ? endpoint : prodEndpoint;

const fileInstance = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

fileInstance.interceptors.request.use(
  function (config) {
    // const userToken = getToken();
    // config.headers.Authorization = "Bearer " + userToken;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

fileInstance.interceptors.response.use(
  function (response) {
    if (response.data.code == 500) {
      //excessão tratada no servidor
      return undefined; // podemos redirecionar para uma tela de ocorreu um erro e tentar nomanete, cmo na funciton error abaixo
    }

    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      clearToken();
      window.location = "/login";
    } else if (500 === error.response.status) {
      // mostrar tela de um erro inesperado na tela
    } else if (403 === error.response.status) {
      // mostrar uma tela de voce nao tem permissao pra finalizar essa opecação
    } else {
      return Promise.reject(error);
    }
  }
);

export default fileInstance;
