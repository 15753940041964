import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer.js";
import themesReducer from "./themes.reducers.js";
import errorsReducer from "./errors.reducer.js";

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  errors: errorsReducer,
});
